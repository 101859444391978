<template>
  <div>
    <el-dialog
      title="上传供应商盖章合同文件"
      :visible.sync="visible"
      width="500px"
    >
      <el-form ref="form" v-model="form">
        <el-form-item label="文件上传：">
          <ImportFile
            ref="upload"
            :import-name="'选择附件'"
            :limit="1"
            :show-file-list="true"
            :accept="'.pdf'"
            :types="['pdf']"
            @getFile="getFile"
            @removeFile="removeFile"
          />
          <span class="mt-4 el-upload__tip">请选择PDF文件进行上传，文件需小于10M</span>
        </el-form-item>
      </el-form>
      <span slot="footer" style="text-align: center;" class="dialog-footer">
        <el-button type="primary" @click="handleUpdata">确 定</el-button>
        <el-button @click="$emit('input',false)">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ImportFile from '@/components/ImportFile'

export default {
  components: { ImportFile },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: undefined
    },
    api: {
      type: Function,
      default: () => {}
    },
    contractType: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      form: { },
      file: '',
      fileList: []
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.file = ''
        this.$refs.upload.$refs.uploadForm.clearFiles()
      }
    }
  },
  methods: {
    getFile({ file, fileList }) {
      this.file = file
    },
    async handleUpdata() {
      if (!this.file) {
        this.$message.error('请上传PDF格式文件!')
        return
      } else {
        const formData = new FormData()
        formData.append('file', this.file)
        formData.append('id', this.id)
        formData.append('contractType', this.contractType)
        try {
          const { code, msg } = await this.api(formData)
          if (code === 0) {
            this.$emit('input', false)
            this.$message.success(msg)
            this.$emit('refresh')
          }
        } finally {
          this.$refs.upload.$refs.uploadForm.clearFiles()
          this.file = ''
        }
      }
    },
    removeFile(val) {
      this.file = val
    }
  }
}
</script>

<style>

</style>
