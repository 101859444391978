<template>
  <div>
    <el-dialog title="文件核对" :visible.sync="visible" width="800px" :close-on-click-modal="false">
      请核对供应商盖章文件是否满足用印申请条件：
      <el-table ref="singleTable" class="mt-2" :data="receiveLogs" highlight-current-row style="width: 100%" @selection-change="handleChange">
        <el-table-column
          type="selection"
          width="50"
        />
        <el-table-column type="index" width="80" label="序号" />
        <el-table-column v-if="flag==='order'" prop="purchaseOrderContractCode" label="合同编号" width="280" />
        <el-table-column v-if="flag!=='order'" prop="agreementCode" label="协议编号" width="280" />
        <el-table-column prop="vendorName" label="供应商" />
        <el-table-column align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleDownload('unilateralUrl',scope.row.id)">下载核对</el-button>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button :disabled="submitFlag" @click="handleSubmit(false)">核对不通过</el-button>
        <el-button type="primary" :disabled="submitFlag" @click="handleSubmit(true)">核对无误</el-button>
      </span>
    </el-dialog>
    <ApplicationSure
      v-model="ApplicationSureDialogVisible"
      :multiple-selection="multipleSelection"
      :api="flag === 'order' ? approval : receiveLogs[0]&&receiveLogs[0].contractType === 1 ? replenishApproval : frameworkApproval"
      @refresh="$parent.handleQuery()"
    />
  </div>
</template>

<script>
import { approval, replenishApproval, frameworkApproval } from '@/api/scm-api'
import ApplicationSure from '../ApplicationSure'

export default {
  components: { ApplicationSure },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    },
    receiveLogs: {
      type: Array,
      default: () => []
    },
    flag: {
      type: String,
      default: ''
    },
    api: {
      type: Function,
      default: () => {}
    },
    download: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      approval, replenishApproval, frameworkApproval,
      form: {},
      multipleSelection: [],
      defaultLogs: [],
      ApplicationSureDialogVisible: false
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }

    },
    submitFlag() {
      return !this.multipleSelection.length
    },
    refuseParams() {
      const idList = []
      this.multipleSelection.map(item => idList.push(item.id))
      return { idList }
    }
  },
  watch: {

  },
  created() {

  },
  mounted() {
  },
  methods: {
    sureApi() {
      const { contractType } = this.receiveLogs[0]
      console.log(contractType)
      return this.flag === 'order' ? approval : contractType === 1 ? replenishApproval : frameworkApproval
    },
    handleChange(val) {
      this.multipleSelection = val
    },
    async handleDownload(fileType, id) {
      const { datas } = await this.download({ fileType, id })
      window.open(datas, '_blank')
    },
    async handleSubmit(flag) {
      if (flag) {
        this.$confirm('请确认已核对完毕供应商上传盖章文件?', '提示', {
          confirmButtonText: '确定',
          showCancelButton: true,
          type: 'warning'
        }).then(() => {
          this.ApplicationSureDialogVisible = true
        })
      } else {
        this.handleRefected()
      }
    },
    async  handleRefected() {
      // 区分不同的接口
      const { code, msg } = await this.api(this.refuseParams)
      if (code === 0) {
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this.$emit('input', false)
        this.$emit('refresh')
      }
    },
    closeDialog() {
      this.$emit('input', false)
    },
    findItem(acc, e) {
      return acc.find(item => item.style === e.style)
    },
    sameItem(item, e) {
      return item.id === e.id && item.packageCode === e.packageCode && item.shoeFoldFlag === e.shoeFoldFlag
    }
  }
}
</script>

<style scoped lang="scss">
</style>
