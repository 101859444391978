<template>
  <div>
    <!-- <img class="img" :src="url" alt="图片加载失败"> -->
    <el-dialog
      title="查看图片"
      :visible.sync="visible"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-image style="width:100%;height:100%" class="img" :src="url" alt="图片加载失败" />
    </el-dialog>
  </div>

</template>

<script>

export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }

}
</script>
<style lang="scss" scoped>
.showPhoto
    {position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    .img
        {display: block;
        margin: auto 0;
        width: 100%;
        text-align: center;}}
</style>

