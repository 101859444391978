<template>
  <div>
    <QueryForm ref="form" v-model="form" :query-btn-loading="tableLoading" @query="handleQuery" @reset="resetClick">

      <el-form-item label="PO单号">
        <el-input
          v-model="form.poCode"
          placeholder="多个以换行符分隔"
          type="textarea"
          autosize
          resize="none"
        />
      </el-form-item>

      <el-form-item label="供应商">
        <el-select v-model="form.vendorId" value-key="id" placeholder="请选择" filterable clearable>
          <el-option
            v-for="item in vendorOptions"
            :key="item.id"
            :label="item.vendorName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="采购主体">
        <el-select v-model="form.cargoOwnerId" value-key="id" placeholder="请选择" filterable clearable>
          <el-option
            v-for="item in baseCargoOwnerData"
            :key="item.id"
            :label="item.cargoOwnerName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="合同编号">
        <el-input v-model="form.poContractCode" />
      </el-form-item>

      <el-form-item label="用印签署状态">
        <Select
          v-model="form.signState"
          :select-options="_getAllCommodityDict('SIGN_STATE')"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="用印类别">
        <Select
          v-model="form.signPrintType"
          :select-options="_getAllCommodityDict('SIGN_PRINT_TYPE')"
          :configuration="optionsConfig"
          clearable
        />
      </el-form-item>
      <el-form-item label="印章所属公司">
        <Select
          v-model="form.organizationCode"
          api-key="organization"
          clearable
        />
      </el-form-item>
      <el-form-item label="印章类型">
        <Select
          v-model="form.signSealType"
          api-key="sealtype"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="签署类型">
        <Select
          v-model="form.signSignatureType"
          :select-options="_getAllCommodityDict('SIGN_SIGNATURE_TYPE')"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>

      <el-form-item label="用印发起人">
        <Select
          v-model="form.signSponsor"
          api-key="newUserList"
          clearable
        />
      </el-form-item>
      <el-form-item label="生成日期">
        <el-date-picker
          v-model="form.value1"
          type="daterange"
          range-separator="~"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="采购员">
        <el-select v-model="form.purchasePersonList" value-key="name" placeholder="请选择" filterable multiple clearable>
          <el-option
            v-for="item in purchaserData"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
      </el-form-item>
    </QueryForm>
    <el-row :span="24" class="mb-3">
      <el-col :span="24">
        <el-button type="primary" :disabled="!multipleSelectionTable.length" @click="handleApply">用印申请</el-button>
        <el-button type="primary" @click="handleDownload">下载</el-button>
      </el-col>
    </el-row>
    <Table
      :table-data="tableData"
      :max-height="'600px'"
      :columns="orderContractcolumns"
      :page-obj="pager"
      :loading="tableLoading"
      :check="true"
      @handleSelectionChange="val => {multipleSelectionTable=val}"
    >
      <el-table-column slot="number" label="PO数量" align="center" width="120">
        <template slot-scope="scope">
          <el-button type="text" :loading="scope.row.numberLoading" @click="showDeatail(scope.row)">{{ scope.row.purchaseOrderNum }}</el-button>
        </template>
      </el-table-column>
      <el-table-column slot="imageURL" label="图片查看" align="center" width="100">
        <template slot-scope="scope">
          <el-button v-if="scope.row.imageUrl" type="text" @click="showImg(scope.row.imageUrl)">查看
          </el-button>
        </template>
      </el-table-column>
      <el-table-column slot="name" label="操作人" align="center" width="130">
        <template slot-scope="scope">
          <span>{{ scope.row.modifyByName ||scope.row.createByName }}</span>
        </template>
      </el-table-column>
      <el-table-column slot="time" label="操作时间" align="center" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.modifyTime ||scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" align="center" fixed="right" width="120">
        <template slot-scope="scope">
          <!-- 生效 确认框 -->
          <el-button v-if="showUpload('upload',scope.row)" type="text" @click="handleUpload(scope.row.id)">上传</el-button>
          <el-button v-if="showUpload('delete',scope.row)" type="text" @click="cancel(scope.row)">作废</el-button>
        </template>
      </el-table-column>
    </Table>
    <div class="block">
      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>

    <UploadPdf :id="id" v-model="centerDialogVisible" :api="uploadPdf" :contract-type="1" @refresh="handleQuery" />
    <DownloadFile v-model="dowmloadDialogVisible" :row="multipleSelectionTable[0]" :file-type-list="fileTypeList" :api="download" />
    <ShowPhoto v-model="photoVisible" :url="url" />
    <PoDetail v-model="dialogContractVisible" :detail-table="contractDetails" :columns="contractPoDetails" :title="'PO详情'" />
    <Application v-model="applyDialogVisible" :receive-logs="multipleSelectionTable" :flag="'order'" :api="approvalCheckRefuse" :download="download" @refresh="handleQuery" />
  </div>

</template>

<script>
import { download, getPurchaserList, approvalCheckRefuse, uploadPdf, updateDownloadNum, queryVendorList, getPOContractLogList, purchaseOrderContractPage, cancelPOContract } from '@/api/scm-api'
import { orderContractcolumns, contractPoDetails } from '@/constant/tablecolumns'
import Table from '@/components/Table'
import Paging from '@/components/Pagination'
import Application from '../components/Application'
import { getCargoOwner } from '@/api/listSelection.js'
import ShowPhoto from '@/components/ImageView'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import QueryForm from '@/components/QueryForm'
import DownloadFile from '../components/DownloadFile'
import UploadPdf from '../components/UploadPdf'
import PoDetail from '../components/PoDetail'
import { preText } from '@/utils/index.js'

/* import FileSaver from 'file-saver'
import JSZip from 'jszip' */
export default {
  components: { PoDetail, UploadPdf, ShowPhoto, Table, Paging, Select, Application, QueryForm, DownloadFile },
  mixins: [commodityInfoDict],

  data() {
    return {
      download,
      approvalCheckRefuse,
      uploadPdf,
      value: '',
      dowmloadDialogVisible: false,
      applyDialogVisible: false,
      photoVisible: false,
      id: undefined,
      imageUrl: '',
      uploadHeaders: {
        authorization: this.$store.getters.authorization
      },
      action: process.env.VUE_APP_UPLOAD_API,
      centerDialogVisible: false,
      statusOptions: [{ label: '生效', value: 1 }, { label: '作废', value: 2 }], contractDetails: [], dialogContractVisible: false,
      form: { value1: [], poContractCode: '', purchasePersonList: [] }, showRow: false, orderContractcolumns, baseCargoOwnerData: [], contractPoDetails,
      purchaserData: [],
      tableLoading: false, vendorOptions: [], tableData: [], multipleSelectionTable: [], pager: { size: 20, current: 1, total: 0 },
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      url: '',
      fileTypeList: [{ label: '原始合同文件（未盖章）', value: 'originUrl' }, { label: '供应商单方盖章文件', value: 'unilateralUrl' }, { label: '双方盖章文件', value: 'multiUrl' }]
    }
  },
  computed: {
    purchaseOrderContractParams() {
      const { value1 = [], vendorId, poCode } = this.form
      const vendorIdList = vendorId ? [vendorId] : []
      const [createStartDate = '', createEndDate = ''] = value1 || []
      return Object.assign({}, this.form, { createStartDate, createEndDate, vendorIdList, poCode: preText(poCode, true) }, this.pager)
    },
    lengthFlag() {
      return this.multipleSelectionTable.length < 11
    }
  },
  watch: {

  },
  mounted() {
    this._queryVendorList()
    this._getCargoOwnerData()
    if (this.$route.query.isPurchase) {
      this.form.poContractCode = this.$route.query.purchaseOrderContractCode
    }
    this._getPurchaserList()
    // this.handleQuery()
  },
  methods: {
    showUpload(flag, { signState, contractStatus }) {
      return flag === 'upload' ? [0, 2, 3, 5, 8].includes(signState) && contractStatus === 1 : [0, 1, 2, 5, 8].includes(signState) && contractStatus !== 2
    },
    handleApply() {
      const cargoFlag = this.multipleSelectionTable.every(item => item.cargoOwnerId === this.multipleSelectionTable[0].cargoOwnerId)
      const statusFlag = this.multipleSelectionTable.every(item => [2, 5, 8].includes(Number(item.signState)))
      const confirmFlag = this.multipleSelectionTable.some(item => [5, 8].includes(Number(item.signState)))
      if (!cargoFlag) {
        this.$message.warning('采购主体不一致，无法发起用印申请！')
        return
      }
      if (!statusFlag) {
        this.$message.warning('仅限印签署状态为“供应商已盖章”、“审批拒绝”、“签署失败”时可操作')
        return
      }
      if (confirmFlag) {
        if (this.lengthFlag) {
          this.$confirm(`${this.multipleSelectionTable.length === 1 ? '当前' : '存在'}合同此前用印申请被拒绝，请确认是否重新发起审批流程?`, '提示', {
            confirmButtonText: '确定',
            showCancelButton: true,
            type: 'warning'
          }).then(() => {
            this.applyDialogVisible = true
          })
        } else {
          return this.$message.warning('至多选择10条数据发起用印申请！')
        }
      } else {
        this.lengthFlag ? this.applyDialogVisible = true : this.$message.warning('至多选择10条数据发起用印申请！')
      }
    },
    showImg(url) {
      if (url) {
        this.photoVisible = true
        this.url = url
      }
    },
    async handleDownload() {
      if (this.multipleSelectionTable.length === 1) {
        this.dowmloadDialogVisible = true
        // const arr = []
        // const codeList = []
        // this.multipleSelectionTable.map(item => arr.push(item.fileUrl))
        // this.multipleSelectionTable.map(item => codeList.push(item.purchaseOrderContractCode))
        // const { datas } = await getDownloadUrlList(arr)
        // datas.forEach(item => {
        //   window.open(item, '_blank')
        // })
        // this._updateDownloadNum(codeList)
      } else {
        this.$message.warning('请勾选一条数据下载')
      }
    },
    // 更新下载次数
    async _updateDownloadNum(codeList) {
      const { code, msg } = await updateDownloadNum(codeList)
      if (code === 0) {
        this.$notify({
          message: msg,
          type: 'success'
        })
        this.handleQuery()
      }
    },
    handleUpload(id) {
      this.centerDialogVisible = true
      this.id = id
    },
    /*     async handleBatchDownload(selectList, name) {
      const self = this
      const data = selectList
      const zip = new JSZip()
      const cache = {}
      const promises = []
      await data.forEach((item) => {
        const name = item.split('download/')[1].split('.')[0]
        const promiseItem = self.downloadByUrl(item, name).then(dta => {
          const arr_name = item.split('/')
          var file_name = arr_name[arr_name.length - 1]
          file_name = file_name.substring(file_name.indexOf('&') + 1, file_name.length - 1)
          zip.file(file_name, dta, { binary: true }) // 逐个添加文件
          cache[file_name] = dta
        })
        promises.push(promiseItem)
      })
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then(content => {
          FileSaver.saveAs(content, name + '等' + data.length + '个.zip')
          this.downLoading = false
        }).catch(err => {
          this.downLoading = false
          console.log(err)
        })
      })
    }, */

    cancel({ id, purchaseOrderContractCode }) {
      this.$prompt('请输入作废原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        inputPattern: /^.{0,200}$/,
        inputErrorMessage: '可输入不超过200字符',
        inputValidator: (value) => {
          this.value = value
        }
      }).then(async() => {
        const { code, msg } = await cancelPOContract({ id, purchaseOrderContractCode, cancelReason: this.value || '' })
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.handleQuery()
        }
      })
    },
    async   showDeatail(row) {
      try {
        this.$set(row, 'numberLoading', true)
        const { code, datas } = await getPOContractLogList({ poContractCode: row.purchaseOrderContractCode })
        if (code === 0) {
          this.contractDetails = datas.map(item => { return { ...item, purchaseOrderCode: item } })
          this.dialogContractVisible = true
        }
      } finally {
        this.$set(row, 'numberLoading', false)
      }
    },
    async _getCargoOwnerData() {
      const { datas, code } = await getCargoOwner()
      if (!code) {
        this.baseCargoOwnerData = datas
      }
    },
    async _getPurchaserList() {
      const { datas } = await getPurchaserList()
      const { allPerson, defaultPerson } = datas
      this.purchaserData = allPerson
      if (defaultPerson) {
        this.form.purchasePersonList = [defaultPerson.name]
        this.handleQuery()
      } else {
        this.handleQuery()
      }
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.handleQuery()
    },
    handleQuery() {
      this.pager.current = 1
      this.queryClick()
    },
    async queryClick() {
      try {
        this.tableLoading = true
        const { datas: { pager, records }} = await purchaseOrderContractPage(this.purchaseOrderContractParams)
        this.pager = pager
        this.tableData = records.map(item => { return { ...item, numberLoading: false, signPrintTypeI18: item.signPrintType ? item.signPrintTypeI18 : '' } })
      } finally {
        this.tableLoading = false
      }
    },
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    }

  }
}
</script>

<style scoped lang="scss">

</style>
