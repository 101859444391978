<template>
  <div>
    <el-upload
      ref="uploadForm"
      :action="action"
      :accept="accept"
      :class="newClass"
      :http-request="uploadFile"
      :limit="limit"
      :show-file-list="showFileList"
      :file-list="fileList"
      :auto-upload="autoUpload"
      :on-change="fileChange"
      :on-remove="fileRemove"
      :before-upload="beforeUpload"
      :on-success="fileSuccess"
      :on-exceed="handleExceed"
      style="border:none!important"
    >
      <el-button :type="buttonType">{{ importName }}</el-button>
    </el-upload>
    <el-dialog :visible.sync="detailDialog" width="90%" title="导入详情" append-to-body>
      <el-row type="flex" justify="end" class="mb-2">
        <el-button v-if="tableDatas.length&&showExport" @click="download">导出</el-button>
        <el-button v-if="showDelete" type="primary" @click="handleDateDeletedetails()">删除</el-button>
      </el-row>
      <vxe-table
        ref="uploadTableDateRef"
        max-height="500px"
        align="center"
        :data="detailsData"
        :cell-style="cellStyle"
        :row-config="{ height: 80 }"
        :checkbox-config="{checkMethod:tableCheckboxConfig}"
        @checkbox-all="selectionChange"
        @checkbox-change="selectionChange"
      >
        <vxe-table-column v-if="canCheck" type="checkbox" width="60" />
        <vxe-table-column type="index" width="60" title="序号" />
        <vxe-table-column field="errorMsg" title="错误信息" min-width="100">
          <template #default="{row}">
            {{ row.errorMsg || row.errorMessage || (row.errorMsgList && row.errorMsgList.length > 0 ? row.errorMsgList.join(';') : '') }}
          </template>
        </vxe-table-column>
        <template v-for="(col, index) in importDetailsColumns">
          <!-- 操作列/自定义列 -->
          <vxe-table-column :key="index" :field="col.prop" :title="col.label" :width="col.width" align="center" />
        </template>
      </vxe-table>
      <el-row type="flex" justify="end" style="margin-top: 20px">
        <el-button @click="$emit('input',false)">取消</el-button>
        <el-button
          type="primary"
          :loading="importSubmitLoading"
          :disabled="handleDisabled()"
          @click="submitDetailDatas"
        >
          提交
        </el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    showConfirm: {
      type: Boolean,
      default: false
    },
    canCheck: {
      type: Boolean,
      default: true
    },
    buttonType: {
      type: String,
      default: 'primary'
    },
    importName: {
      type: String,
      default: '导入'
    },
    accept: {
      type: String,
      default: '.xlsx, .xls'
    },
    limit: {
      type: Number,
      default: 1
    },
    size: {
      type: Number,
      default: 10
    },
    showExport: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    autoUpload: {
      type: Boolean,
      default: false
    },
    showFileList: {
      type: Boolean,
      default: false
    },
    showFileDetails: {
      type: Boolean,
      default: false
    },
    uploadFile: {
      type: Function,
      default: () => { }
    },
    value: {
      type: Boolean,
      default: false
    },
    importSubmitLoading: {
      type: Boolean,
      default: false
    },
    detailErrorTip: {
      type: Boolean,
      default: false
    },
    detailsData: {
      type: Array,
      default: () => []
    },
    importDetailsColumns: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    },
    action: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileList: [],
      file: '',
      multipleSelection: []
    }
  },
  computed: {
    detailDialog: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    tableDatas() {
      return this.detailsData.length && this.detailsData.filter(item => item.errorMsg || item.errorMessage || (item.errorMsgList && item.errorMsgList.length > 0 ? item.errorMsgList.join(';') : '')) || []
    },
    newClass() {
      if (this.showFileList) {
        return 'avatar-uploader'
      } else {
        return 'el-button padding-none inline '
      }
    }

  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleDisabled() {
      return this.canCheck ? !this.multipleSelection.length : (this.detailsData.some(row => row.errorMsg || row.errorMessage || (row.errorMsgList && row.errorMsgList.length)))
    },
    cellStyle({ row }) {
      if (this.isErrorRow(row)) {
        return {
          color: 'red'
        }
      }
    },
    handleExceed(files, fileList) {
      this.$refs.uploadForm.clearFiles()
      this.fileList = fileList.slice(-1)
      const file = files[0]
      this.$refs.uploadForm.handleStart(file)
      this.$emit('handleExceed', { files, fileList })
    },
    fileSuccess(files, fileList) {
      this.$emit('handleSuccess', { files, fileList })
    },
    handleDateDeletedetails() {
      const data = this.$refs.uploadTableDateRef.$refs.table.selection
      data.map(e => {
        var index = this.detailsData.findIndex((i) => {
          return e.purchaseOrderCode === i.purchaseOrderCode
        })
        index !== -1 && this.detailsData.splice(index, 1) && this.multipleSelection.splice(index, 1)
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, i) => filterVal.map(j => {
        return v[j]
      }))
    },
    download() {
      import('@/vendor/Export2Excel').then(excel => {
        const header = ['错误信息', '平台', '站点', 'Sku', 'Style', 'Color', 'Size', '交货数量1', '期望交期1', '交货数量2', '期望交期2', '交货数量3', '期望交期3', '交货数量4', '期望交期4']
        const filterVal = ['errorMsg', 'platformName', 'siteName', 'sku', 'style', 'color', 'size', 'expectPairs1', 'expectDeliveryDate1', 'expectPairs2', 'expectDeliveryDate2', 'expectPairs3', 'expectDeliveryDate3', 'expectPairs4', 'expectDeliveryDate4']
        const data = this.formatJson(filterVal, this.tableDatas)
        excel.export_json_to_excel({
          header,
          data,
          filename: `An List ${dayjs(new Date()).format('YYYY/MM/DD')}`
        })
      })
    },
    isErrorRow(row) {
      return row.errorMsg || row.errorMessage || (row.errorMsgList && row.errorMsgList.length)
    },
    tableCheckboxConfig({ row }) {
      if (this.isErrorRow(row) && !this.detailErrorTip) {
        return false
      } else {
        return true
      }
    },
    selectionChange({ records }) {
      this.multipleSelection = records
      this.$emit('getSelectionChange', records)
    },
    closeDetailDialog() {
      this.$emit('input', false)
    },
    submitDetailDatas() {
      if (!this.multipleSelection.length && this.canCheck) {
        this.$message.warning('请至少选择一条明细')
        return
      }

      const indexList = []
      this.multipleSelection.map((item) => {
        if (this.isErrorRow(item)) {
          indexList.push(item.index)
        }
      })
      if (this.detailErrorTip && indexList.length) {
        return this.$message.warning(`第${indexList}行数据校验不通过，无法提交，请按照错误提示修改数据后再进行导入`)
      }
      if (!this.showConfirm) {
        this.$confirm('该操作无法撤销，请仔细核对！', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$emit('submitDetailDatas')
        })
      } else {
        this.$emit('submitDetailDatas')
      }
    },
    fileChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
      if (this.beforeUpload(file.raw)) {
        this.$emit('getFile', { file: file.raw, fileList })
      } else {
        this.file = ''
        this.fileList = []
        this.$emit('getFile', { file: '', fileList: [] })
      }
    },
    fileRemove() {
      this.file = ''
      this.fileList = []
      this.$emit('removeFile', '')
    },
    closeImportDialog() {
      this.importDialog = false
    },
    clearFile() {
      this.file = ''
      this.fileList = []
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < this.size
      if (!isLt2M) {
        this.$message.error(`上传失败，文件需小于${this.size}M`)
        return false
      }
      const type = file.name.split('.').pop()
      const isType = this.types.includes(type)
      if (this.types.length && !isType) {
        this.$message.error('请上传正确格式文件!')
        return false
      }
      return true
    },
    clearFiles() {
      this.$refs.uploadForm.clearFiles()

      this.multipleSelection = []
    }
  }
}
</script>

<style scoped lang="scss">
.inline {
  display: inline-block !important;
}
/deep/ .avatar-uploader .el-upload {
  // border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

</style>
